import { TGuid } from '@core/helpers';
import { EProfileTypeDTO } from './_index';

export interface IProfileTagDTO {
  id: TGuid;
  name: string;
  disable_scheduling: boolean;
  disable_live_queue: boolean;
  disable_access: boolean;
  cnes: ICnes;
  public_name: string;
  public: boolean;
}

export interface IProfileTagCreateDTO {
  name: string;
  profile_type: EProfileTypeDTO;
  disable_scheduling: boolean;
  disable_live_queue: boolean;
  disable_access: boolean;
  cnes_code: string;
  public_name: string;
  public: boolean;
}

export interface IProfileTagCreateData {
  name: string;
  profile_type: EProfileTypeDTO;
  disable_scheduling: boolean;
  disable_live_queue: boolean;
  disable_access: boolean;
  cnes_code: string;
  public_name: string;
  public: boolean;
}

export interface IProfileTag {
  id: TGuid;
  name: string;
  disable_scheduling: boolean;
  disable_live_queue: boolean;
  disable_access: boolean;
  cnes: ICnes;
  public_name: string;
  public: boolean;
}

export interface ICnesResponse {
  items: ICnes[];
  totalItemsCount: number;
}

export interface ICnes {
  cnes_code: string;
  sigla_estado: string;
  municipio: string;
  nome_estabelecimento: string;
}

export interface ICityCnes {
  municipio: string;
  sigla_estado: string;
}

export class ProfileTagViewModelFactory {
  static createGetViewModel(dto: IProfileTagDTO): IProfileTag {
    return {
      ...dto,
    };
  }

  static createPostDTO(data: IProfileTagCreateData): IProfileTagCreateDTO {
    return {
      ...data,
    };
  }
}

import { EProfileTypeDTO } from './../../../view-models/consts/profile-type';
import { Component, EventEmitter, Input } from '@angular/core';
import { IModalComponent } from '@lib/modal';
import { IProfileTag } from '@project/view-models';
import { FormComponent } from '@project/shared';
import { FormBuilder } from '@angular/forms';
import { LibValidators } from '@core/helpers';
import { TranslateService } from '@project/translate';
import { ProfilesTagsApiProviderService } from '@project/data-providers';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { NotificationsService } from '@lib/notifications';

@Component({
  selector: 'app-profile-tag-settings-overlay',
  templateUrl: './profile-tag-settings-overlay.component.html',
  styleUrls: ['./profile-tag-settings-overlay.component.scss'],
})
export class ProfileTagSettingsOverlayComponent extends FormComponent implements IModalComponent {
  @Input() tag: IProfileTag | null = null;
  profileType: EProfileTypeDTO = null;

  private readonly _isRemoving$ = new BehaviorSubject<boolean>(false);
  public readonly isRemoving$ = this._isRemoving$.asObservable();

  close$ = new EventEmitter<IProfileTag | null>();
  submit$ = new EventEmitter<IProfileTag | null>();
  remove$ = new EventEmitter<IProfileTag | null>();

  form = this.fb.group({
    id: [{ value: '', disabled: true }],
    name: ['', LibValidators.required(TranslateService.localize('validations.required'))],
    disable_scheduling: [false],
    disable_live_queue: [false],
    disable_access: [false],
    public_name: ['', LibValidators.required(TranslateService.localize('validations.required'))],
    public: [false],
  });

  constructor(
    private fb: FormBuilder,
    private profilesTagsApiProviderService: ProfilesTagsApiProviderService,
    private notificationsService: NotificationsService,
  ) {
    super();
  }

  close() {
    if (this._submitting$.value) {
      return;
    }
    this.close$.emit(this.tag || null);
  }

  remove() {
    if (!this.tag) {
      return;
    }

    this._isRemoving$.next(true);

    this.setStartSubmitState();
    this.profilesTagsApiProviderService.remove(this.tag.id).subscribe({
      next: () => {
        this.setEndSubmitState();
        this.remove$.emit(this.tag || null);
        this.notificationsService.success({
          message: TranslateService.localize('profile-tags.settings-overlay.remove.success-message'),
        });
      },
      error: (error: HttpErrorResponse) => {
        this.setEndSubmitState(error);
        this._isRemoving$.next(false);
        this.notificationsService.error({
          message: TranslateService.localize('profile-tags.settings-overlay.remove.error-message'),
        });
      },
    });
  }

  protected onFormInit() {
    if (this.tag) {
      this.form.patchValue(this.tag);
    }
  }

  protected doCleanSubmit() {
    if (this.tag) {
      this.update(this.tag);
    } else {
      this.create();
    }
  }

  private update(tag: IProfileTag) {
    this.setStartSubmitState();
    this.profilesTagsApiProviderService
      .update(tag.id, {
        name: this.form.value.name,
        disable_live_queue: this.form.value.disable_live_queue,
        profile_type: this.profileType,
        disable_scheduling: this.form.value.disable_scheduling,
        disable_access: this.form.value.disable_access,
        cnes_code: this.form.value.cnes,
        public_name: this.form.value.public_name,
        public: this.form.value.public,
      })
      .subscribe({
        next: () => {
          this.setEndSubmitState();
          this.submit$.emit(tag);
          this.notificationsService.success({
            message: TranslateService.localize('profile-tags.settings-overlay.update.success-message'),
          });
        },
        error: (error: HttpErrorResponse) => {
          this.setEndSubmitState(error);

          const message =
            typeof error.error === 'string'
              ? error.error
              : TranslateService.localize('profile-tags.settings-overlay.update.error-message');

          this.notificationsService.error({
            title: TranslateService.localize('nouns.error'),
            message,
          });
        },
      });
  }

  private create() {
    this.setStartSubmitState();

    this.profilesTagsApiProviderService
      .create({
        name: this.form.value.name,
        disable_scheduling: this.form.value.disable_scheduling,
        profile_type: this.profileType,
        disable_live_queue: this.form.value.disable_live_queue,
        disable_access: this.form.value.disable_access,
        cnes_code: this.form.value.cnes,
        public_name: this.form.value.public_name,
        public: this.form.value.public,
      })
      .subscribe({
        next: (tag) => {
          this.setEndSubmitState();
          this.submit$.emit(tag);
          this.notificationsService.success({
            message: TranslateService.localize('profile-tags.settings-overlay.create.success-message'),
          });
        },
        error: (error: HttpErrorResponse) => {
          this.setEndSubmitState(error);

          const message =
            typeof error.error === 'string'
              ? error.error
              : TranslateService.localize('profile-tags.settings-overlay.create.error-message');

          this.notificationsService.error({
            title: TranslateService.localize('nouns.error'),
            message,
          });
        },
      });
  }
}

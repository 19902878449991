import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EProfileTypeDTO, ICnes, IProfileTag, IProfileTagCreateData } from '@project/view-models';
import { FormComponent, INPUT_MASKS, INPUT_PLACEHOLDERS } from '@project/shared';
import { FormBuilder } from '@angular/forms';
import { LibValidators } from '@core/helpers';
import { TranslateService } from '@project/translate';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfilesTagsApiProviderService } from '@project/data-providers';
import { NotificationsService } from '@lib/notifications';
import { BehaviorSubject } from 'rxjs';
import { GroupsManagerService } from '../../../../../lazy-modules/groups-management/services/groups-manager.service';
import { HcService } from '@project/services';
import { ModalCnesComponent } from '../../../../components/modal-cnes/modal-cnes.component';
import { IModalComponentRef, ModalOverlayService } from '@lib/modal';
import { Router } from '@angular/router';
import { RoutesBuilderService } from '@app/config';

@Component({
  selector: 'app-groups-invite-details-form',
  templateUrl: './groups-invite-details-form.component.html',
  styleUrls: ['./groups-invite-details-form.component.scss'],
})
export class GroupsInviteDetailsFormComponent extends FormComponent implements OnInit {
  private modalCnesRef: IModalComponentRef<ModalCnesComponent>;
  @Output() dataSubmit = new EventEmitter<IProfileTagCreateData>();
  @Input() group: IProfileTag | null = null;
  @Input() isPending = false;
  profileType: EProfileTypeDTO = null;

  public form = this.fb.group({
    id: [{ value: '', disabled: true }],
    name: ['', LibValidators.required(TranslateService.localize('validations.required'))],
    public_name: ['', LibValidators.required(TranslateService.localize('validations.required'))],
    public: [false],
    cnes: [
      '',
      this.hcService.getHcConfig().isPublic
        ? LibValidators.required(TranslateService.localize('validations.required'))
        : null,
    ],
    disable_scheduling: [false],
    disable_live_queue: [false],
    disable_access: [false],
  });

  private readonly cnesSelectedPub$ = new BehaviorSubject<string>('');
  public readonly cnesSelected$ = this.cnesSelectedPub$.asObservable();

  private readonly _isRemoving$ = new BehaviorSubject<boolean>(false);
  public readonly isRemoving$ = this._isRemoving$.asObservable();

  close$ = new EventEmitter<IProfileTag | null>();
  submit$ = new EventEmitter<IProfileTag | null>();
  remove$ = new EventEmitter<IProfileTag | null>();

  public readonly dateInputMask = INPUT_MASKS.date;
  public readonly dateInputPlaceholder = INPUT_PLACEHOLDERS.date;

  constructor(
    private fb: FormBuilder,
    private profilesTagsApiProviderService: ProfilesTagsApiProviderService,
    private notificationsService: NotificationsService,
    private groupsManagerService: GroupsManagerService,
    public hcService: HcService,
    private modalOverlayService: ModalOverlayService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    if (!this.profileType) {
      this.profileType = EProfileTypeDTO.Patient;
    }

    if (this.group) {
      this.form.patchValue(this.group);
    }
    if (this.group?.cnes) {
      this.cnesSelectedPub$.next(`${this.group.cnes.cnes_code} - ${this.group.cnes.nome_estabelecimento}`);
      this.form.patchValue({ cnes: this.group.cnes });
    }
  }

  protected onFormInit() {}

  private createGroup() {
    this.setStartSubmitState();

    this.profilesTagsApiProviderService
      .create({
        name: this.form.value.name,
        disable_scheduling: this.form.value.disable_scheduling,
        profile_type: this.profileType,
        disable_live_queue: this.form.value.disable_live_queue,
        disable_access: this.form.value.disable_access,
        cnes_code: this.form.value.cnes.cnes_code,
        public_name: this.form.value.public_name,
        public: this.form.value.public,
      })
      .subscribe({
        next: (tag) => {
          this.setEndSubmitState();
          this.submit$.emit(tag);
          this.notificationsService.success({
            message: TranslateService.localize('profile-tags.settings-overlay.create.success-message'),
          });
          this.resetForm();
          this.groupsManagerService.loadGroups().subscribe();
        },
        error: (error: HttpErrorResponse) => {
          this.setEndSubmitState(error);
          const message =
            error.error?.mensagem || TranslateService.localize('profile-tags.settings-overlay.update.error-message');
          this.notificationsService.error({
            title: TranslateService.localize('nouns.error'),
            message,
          });
        },
      });
  }

  private resetForm() {
    this.form.reset({
      public: false,
      disable_scheduling: false,
      disable_live_queue: false,
      disable_access: false,
    });
    this.form.patchValue({ cnes: '' });
    this.cnesSelectedPub$.next('');
  }

  remove() {
    if (!this.group) {
      return;
    }

    this._isRemoving$.next(true);

    this.setStartSubmitState();
    this.profilesTagsApiProviderService.remove(this.group.id).subscribe({
      next: () => {
        this.setEndSubmitState();
        this.remove$.emit(this.group || null);
        this.notificationsService.success({
          message: TranslateService.localize('profile-tags.settings-overlay.remove.success-message'),
        });
      },
      error: (error: HttpErrorResponse) => {
        this.setEndSubmitState(error);
        this._isRemoving$.next(false);
        this.notificationsService.error({
          message: TranslateService.localize('profile-tags.settings-overlay.remove.error-message'),
        });
      },
    });
  }

  private updateGroup(tag: IProfileTag) {
    this.setStartSubmitState();

    const updatedGroupData: IProfileTagCreateData = {
      name: this.form.value.name,
      disable_live_queue: this.form.value.disable_live_queue,
      profile_type: this.profileType,
      disable_scheduling: this.form.value.disable_scheduling,
      disable_access: this.form.value.disable_access,
      public_name: this.form.value.public_name,
      public: this.form.value.public,
      cnes_code: null,
    };

    if (this.hcService.getHcConfig().isPublic) {
      updatedGroupData.cnes_code = this.form.value.cnes?.cnes_code || null;
    }

    this.profilesTagsApiProviderService.update(tag.id, updatedGroupData).subscribe({
      next: () => {
        this.setEndSubmitState();
        this.submit$.emit(tag);
        this.notificationsService.success({
          message: TranslateService.localize('profile-tags.settings-overlay.update.success-message'),
        });
        this.groupsManagerService.loadGroups().subscribe();
        this.router.navigate(
          RoutesBuilderService.ASIDE_NAVIGATION.asideRoute({
            route: tag.id,
            relativeRoute: RoutesBuilderService.GROUPS_MANAGEMENT.root(),
          }),
        );
      },
      error: (error: HttpErrorResponse) => {
        this.setEndSubmitState(error);

        if (error.status === 500) {
          this.notificationsService.error({
            title: TranslateService.localize('nouns.error'),
            message: TranslateService.localize('profile-tags.settings-overlay.update.cnes-already-used'),
          });
        } else {
          this.notificationsService.error({
            title: TranslateService.localize('nouns.error'),
            message: error.error?.mensagem || TranslateService.localize('errors.unexpected-error'),
          });
        }
      },
    });
  }

  openCnesModal() {
    this.modalCnesRef = this.modalOverlayService.openOverlay<ModalCnesComponent>(ModalCnesComponent);

    this.modalCnesRef.instance.selectCnes$.subscribe((selectedCnes: ICnes) => {
      if (selectedCnes) {
        this.cnesSelectedPub$.next(`${selectedCnes.cnes_code} - ${selectedCnes.nome_estabelecimento}`);
        this.form.patchValue({ cnes: selectedCnes });
        this.modalCnesRef.close();
      }
    });

    this.modalCnesRef.instance.close$.subscribe(() => {
      this.modalCnesRef.close();
    });
  }

  doCleanSubmit() {
    if (this.group) {
      this.updateGroup(this.group);
    } else {
      this.createGroup();
    }
  }
}

<lib-modal-header (close)="close()">{{ "select-cnes" | translate }}</lib-modal-header>

<lib-modal-body>
  <div class="search-filter-container">
    <lib-button-dropdown [label]="selectedCity?.municipio || ('filter-city-all' | translate)" [appearance]="'outline'">
      <lib-button-dropdown-menu-item (click)="onSelectCity(null)">
        {{ "filter-city-all" | translate }}
      </lib-button-dropdown-menu-item>

      <lib-button-dropdown-menu-item *ngFor="let city of cities" (click)="onSelectCity(city)">
        {{ city.municipio }}
      </lib-button-dropdown-menu-item>
    </lib-button-dropdown>

    <lib-button-dropdown
      [label]="sortOrder === 'asc' ? ('sort-asc' | translate) : ('sort-desc' | translate)"
      [appearance]="'outline'"
    >
      <lib-button-dropdown-menu-item (click)="onSelectOrder('asc')">
        {{ "sort-asc" | translate }}
      </lib-button-dropdown-menu-item>

      <lib-button-dropdown-menu-item (click)="onSelectOrder('desc')">
        {{ "sort-desc" | translate }}
      </lib-button-dropdown-menu-item>
    </lib-button-dropdown>

    <lib-search-input
      [size]="'m'"
      [placeholder]="'search-cnes-placeholder' | translate"
      [ngModel]="searchQuery"
      (ngModelChange)="search($event)"
    ></lib-search-input>
  </div>

  <div *ngIf="isLoading" class="loading-container">
    <mat-spinner diameter="50" class="custom-spinner"></mat-spinner>
    <p>{{ "loading-cnes" | translate }}</p>
  </div>

  <app-list-items-table
    *ngIf="!isLoading && !noResultsFound"
    [listItemsServiceToken]="cnesListServiceToken"
    [allowSelection]="false"
  >
    <tr appListTableHead>
      <th>{{ "noun.code" | translate }}</th>
      <th>{{ "noun.name" | translate }}</th>
      <th></th>
      <th>{{ "noun.state" | translate }}</th>
      <th>{{ "noun.city" | translate }}</th>
      <th>{{ "noun.act" | translate }}</th>
    </tr>

    <tr *ngFor="let cnes of cnesObservable | async" appListTableItem [idToSelect]="cnes.cnes_code" class="hovered-row">
      <td>{{ cnes.cnes_code }}</td>
      <td colspan="2">{{ cnes.nome_estabelecimento }}</td>
      <td>{{ cnes.sigla_estado }}</td>
      <td>{{ cnes.municipio }}</td>
      <td>
        <button libButton type="button-select-cnes" (click)="selectCnes(cnes)" [appearance]="'outline'">
          {{ "title-cnes-button" | translate }}
        </button>
      </td>
    </tr>
  </app-list-items-table>

  <div *ngIf="totalItemsCount > itemsPerPage && !isLoading && !noResultsFound" class="table-paging-control-container">
    <lib-paging-control
      [page]="currentPage"
      [pagesTotal]="Math.ceil(totalItemsCount / itemsPerPage)"
      (pageChange)="onPageChange($event)"
    >
    </lib-paging-control>
  </div>
</lib-modal-body>

<lib-modal-footer>
  <div class="footer-right">
    <button libButton type="button" (click)="close()" [appearance]="'outline'">
      {{ "actions.close" | translate }}
    </button>
  </div>
</lib-modal-footer>

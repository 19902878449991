import { ChangeDetectorRef, Component, EventEmitter, InjectionToken, OnInit } from '@angular/core';
import { IModalComponent } from '@lib/modal';
import { ICityCnes, ICnes, ICnesResponse } from '@project/view-models';
import { BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfilesTagsApiProviderService } from '@project/data-providers';
import { HcService } from '@project/services';
import { finalize, map } from 'rxjs/operators';
import { TranslateService } from '@project/translate';
import { NotificationsService } from '@lib/notifications';

export const CNES_LIST_SERVICE_TOKEN = new InjectionToken('List of Cnes');

@Component({
  selector: 'app-modal-cnes',
  templateUrl: './modal-cnes.component.html',
  styleUrls: ['./modal-cnes.component.scss'],
})
export class ModalCnesComponent implements OnInit, IModalComponent<any> {
  public close$ = new EventEmitter<void>();
  public selectCnes$ = new EventEmitter<ICnes>();
  public submit$ = new EventEmitter<void>();

  public searchQuery?: string;
  public selectedCity?: ICityCnes;

  public readonly Math = Math;

  public isLoading = false;
  public noResultsFound = false;
  public sortOrder: 'asc' | 'desc' = 'asc';

  public cnesListServiceToken = CNES_LIST_SERVICE_TOKEN;

  public cnesList: ICnes[] = [];
  public cities: ICityCnes[] = [];

  public currentPage = 1;
  public itemsPerPage = 8;
  public totalItemsCount = 134570;
  public offset = 0;
  public paginates: {
    page: number;
    list: ICnes[];
  }[] = [];

  private readonly cnesPub = new BehaviorSubject<ICnes[]>([]);
  public readonly cnesObservable = this.cnesPub.asObservable();

  constructor(
    private profilesTagsApiProviderService: ProfilesTagsApiProviderService,
    public hcService: HcService,
    private cdr: ChangeDetectorRef,
    private notificationsService: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.loadCities();
    this.loadCnes();
  }

  loadCities() {
    this.profilesTagsApiProviderService.getCityCnes(this.hcService.getHcConfig().uf).subscribe({
      next: (cities: ICityCnes[]) => {
        this.cities = cities;
      },
      error: (error: HttpErrorResponse) => {
        this.handleError(error, 'errors.load-cities');
      },
    });
  }

  loadCnes() {
    this.isLoading = true;
    this.noResultsFound = false;
    this.cdr.detectChanges();
    this.profilesTagsApiProviderService
      .getCnes(
        this.itemsPerPage,
        this.currentPage,
        this.hcService.getHcConfig().uf,
        this.selectedCity?.municipio,
        this.searchQuery,
      )
      .subscribe({
        next: (response) => {
          this.handleEmptyCnesList(response.items);
          this.totalItemsCount = response.totalItemsCount;

          this.isLoading = false;
          this.cdr.detectChanges();

          response.items.forEach((item) => {
            this.cnesList.push(item);
          });

          this.paginates.push({
            page: this.currentPage,
            list: response.items,
          });
          this.isLoading = false;
          this.cdr.detectChanges();

          this.updatePageData();
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error, 'errors.load-cnes');
          this.isLoading = false;
          this.cdr.detectChanges();
        },
      });
  }

  handleError(error: HttpErrorResponse, contextKey: any): void {
    if (error.status >= 400 && error.status < 600) {
      this.notificationsService.error({
        title: TranslateService.localize('nouns.error'),
        message: TranslateService.localize(contextKey),
        durationMs: 5000,
      });
    } else {
      this.notificationsService.error({ message: TranslateService.localize('errors.unexpected-error') });
    }
  }

  handleEmptyCnesList(response: ICnes[]): void {
    if (this.currentPage === 1 && response.length === 0) {
      this.noResultsFound = true;

      this.notificationsService.info({
        message: TranslateService.localize('empty-cnes-search-list'),
        durationMs: 5000,
      });
    }
  }

  selectCnes(cnes: ICnes) {
    this.selectCnes$.emit(cnes);
  }

  close() {
    this.close$.emit();
  }

  onSelectCity(city: ICityCnes) {
    this.selectedCity = city;
    this.loadCnes();
    this.onPageChange(1);
  }

  onSelectOrder(order: 'asc' | 'desc') {
    this.sortOrder = order;
    this.sortByName();
  }

  search(query: string) {
    this.searchQuery = query.trim();
    this.currentPage = 1;
    this.offset = 0;
    this.cnesList = [];
    this.paginates = [];
    this.noResultsFound = false;
    this.loadCnes();
  }

  private sortByName() {
    this.cnesPub.next(
      this.cnesPub.value.sort((a, b) => {
        const nameA = a.nome_estabelecimento.toLowerCase();
        const nameB = b.nome_estabelecimento.toLowerCase();

        return this.sortOrder === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
      }),
    );
  }

  onPageChange(page: number) {
    this.currentPage = page;

    const hasResult = this.paginates.some((item) => {
      return item.page === page;
    });

    if (hasResult) {
      this.updatePageData();
    } else {
      this.offset = (page - 1) * this.itemsPerPage;
      this.loadCnes();
    }
  }

  updatePageData() {
    const result = this.paginates.find((item) => {
      return item.page === this.currentPage;
    });

    this.cnesPub.next(result.list);
  }
}

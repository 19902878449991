<form [formGroup]="form" (ngSubmit)="doCleanSubmit()">
  <div class="row">
    <div class="col-6">
      <lib-form-control>
        <label>
          <span libLabel>{{ "log-profile-id" | translate }}</span>
          <lib-input formControlName="id"></lib-input>
        </label>
      </lib-form-control>
    </div>
    <div class="col-6">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.name" | translate }}</span>
          <lib-input formControlName="name"></lib-input>
        </label>
      </lib-form-control>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.public_name" | translate }}</span>
          <lib-input formControlName="public_name"></lib-input>
        </label>
      </lib-form-control>
    </div>
    <div class="col-12">
      <lib-form-control>
        <label>
          <span class="slide-toggle_title" libLabel>{{ "patients.tag-public" | translate }}</span>
          <mat-slide-toggle color="primary" formControlName="public"></mat-slide-toggle>
        </label>
      </lib-form-control>
    </div>
  </div>

  <div class="row align-items-center" *ngIf="hcService.getHcConfig().isPublic">
    <div class="col-9">
      <lib-form-control>
        <label style="background-color: #0e9ae6 !important;">
          <span libLabel>{{ "noun-cnes" | translate }}</span>
          <lib-input [value]="cnesSelected$ | async" [disabled]="true"> </lib-input>
        </label>
      </lib-form-control>
    </div>

    <div class="cnes-button-box">
      <button libButton type="button" (click)="openCnesModal()" [appearance]="'outline'" [size]="'m'">
        {{ "title-cnes-button" | translate }}
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <lib-form-control>
        <label>
          <span class="slide-toggle_title" libLabel>{{ "patients.tag-disable-scheduling" | translate }}</span>
          <mat-slide-toggle color="primary" formControlName="disable_scheduling"></mat-slide-toggle>
        </label>
      </lib-form-control>
    </div>
    <div class="col-6">
      <lib-form-control>
        <label>
          <span class="slide-toggle_title" libLabel>{{ "patients.tag-disable-live-queue" | translate }}</span>
          <mat-slide-toggle color="primary" formControlName="disable_live_queue"></mat-slide-toggle>
        </label>
      </lib-form-control>
    </div>
    <div class="col-6">
      <lib-form-control>
        <label>
          <span class="slide-toggle_title" libLabel>{{ "patients.tag-disable-access" | translate }}</span>
          <mat-slide-toggle color="primary" formControlName="disable_access"></mat-slide-toggle>
        </label>
      </lib-form-control>
    </div>
  </div>

  <button
    libButton
    [isPending]="isPending"
    type="submit"
    [disabled]="!(form.valid && !isPending)"
    [appearance]="'outline'"
    [size]="'m'"
  >
    {{ group == null ? ("actions.create" | translate) : ("actions.group-edit-button" | translate) }}
  </button>
</form>

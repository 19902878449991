// noinspection ES6PreferShortImport
import { EProfileTypeDTO } from '../view-models/consts/profile-type'; // Should use this import to prevent circular   dependencies

export enum EUserPermission {
  manageHealthCenterAdmins = 'manageHealthCenterAdmins',
  manageGeneralAdmins = 'manageGeneralAdmins',
  manageDoctors = 'manageDoctors',
  managePatients = 'managePatients',
  manageHealthCenters = 'manageHealthCenters',
  manageReceptionists = 'manageReceptionists',
  manageHealthCenterWorkingHours = 'manageHealthCenterWorkingHours',

  manageSessionsLogs = 'manageSessionsLogs',
  manageLoginLogs = 'manageLoginLogs',
  manageSendgrid = 'manageSendgrid',
  manageSelfHealthCenterSettings = 'manageSelfHealthCenterSettings',

  inviteHealthCenterAdmins = 'inviteHealthCenterAdmins',
  inviteGeneralAdmins = 'inviteGeneralAdmins',
  inviteDoctors = 'inviteDoctors',
  invitePatients = 'invitePatients',
  inviteReceptionists = 'inviteReceptionists',
  inviteGroups = 'inviteGroups',

  removeHealthCenters = 'removeHealthCenters',
  removeHealthCenterAdmins = 'removeHealthCenterAdmins',
  removeGeneralAdmins = 'removeGeneralAdmins',
  removeDoctors = 'removeDoctors',
  removePatients = 'removePatients',
  removeReceptionists = 'removeReceptionists',

  haveReceptionistsSession = 'haveReceptionistsSession',
  haveDoctorSession = 'haveDoctorSession',
  havePatientSession = 'havePatientSession',
  responseToSession = 'responseToSession',
  createSummaryNotes = 'createSummaryNotes',
  makeCall = 'makeCall',

  viewObservations = 'viewObservations',
  viewOwnObservationsAsPatient = 'viewOwnObservationsAsPatient',

  haveChatWithTechnicalSupport = 'haveChatWithTechnicalSupport',
  haveToBeAskedToCloseRequisitionsOnLogout = 'haveToBeAskedToCloseRequisitionsOnLogout',
  haveToAddSummaryNoteOnCloseConversationIfHasNoOne = 'haveToAddSummaryNoteOnCloseConversationIfHasNoOne',
}

export const PERMISSIONS_BY_PROFILE_ROLE: Record<EProfileTypeDTO, EUserPermission[]> = {
  [EProfileTypeDTO.Admin]: [
    EUserPermission.manageGeneralAdmins,
    EUserPermission.manageHealthCenterAdmins,
    EUserPermission.inviteHealthCenterAdmins,
    EUserPermission.inviteGeneralAdmins,
    EUserPermission.manageHealthCenters,
    EUserPermission.removeHealthCenterAdmins,
    EUserPermission.removeGeneralAdmins,
    EUserPermission.manageSessionsLogs,
    EUserPermission.manageLoginLogs,
    EUserPermission.manageSendgrid,
  ],
  [EProfileTypeDTO.HealthCenterAdmin]: [
    EUserPermission.manageDoctors,
    EUserPermission.managePatients,
    EUserPermission.manageHealthCenterAdmins,
    EUserPermission.inviteDoctors,
    EUserPermission.invitePatients,
    EUserPermission.inviteHealthCenterAdmins,
    EUserPermission.inviteReceptionists,
    EUserPermission.inviteGroups,
    EUserPermission.removeDoctors,
    EUserPermission.removePatients,
    EUserPermission.removeReceptionists,
    EUserPermission.removeHealthCenterAdmins,
    EUserPermission.manageReceptionists,
    EUserPermission.manageHealthCenterWorkingHours,
    EUserPermission.manageSelfHealthCenterSettings,
    EUserPermission.manageSessionsLogs,
    EUserPermission.manageLoginLogs,
    EUserPermission.manageSendgrid,
  ],
  [EProfileTypeDTO.Doctor]: [
    EUserPermission.haveDoctorSession,
    EUserPermission.haveChatWithTechnicalSupport,
    EUserPermission.createSummaryNotes,
    EUserPermission.haveToAddSummaryNoteOnCloseConversationIfHasNoOne,
    EUserPermission.viewObservations,
    EUserPermission.makeCall,
  ],
  [EProfileTypeDTO.Patient]: [
    EUserPermission.havePatientSession,
    EUserPermission.responseToSession,
    EUserPermission.haveToBeAskedToCloseRequisitionsOnLogout,
    EUserPermission.viewOwnObservationsAsPatient,
  ],
  [EProfileTypeDTO.Receptionist]: [
    EUserPermission.haveReceptionistsSession,
    EUserPermission.makeCall,
    EUserPermission.managePatients,
    EUserPermission.invitePatients,
    EUserPermission.removePatients,
    EUserPermission.manageSessionsLogs,
  ],
};

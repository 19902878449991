<ng-container>
  <ng-container>
    <lib-button-dropdown
      [label]="(selectedTag$ | async)?.name || ('actions.select-group' | translate)"
      [appearance]="'primary'"
    >
      <lib-button-dropdown-menu-item (click)="setTagToPatient(null)">
        {{ "patients.empty-group-menu-label" | translate }}
      </lib-button-dropdown-menu-item>
      <lib-button-dropdown-menu-item *ngFor="let tag of profileTags$ | async" (click)="setTagToPatient(tag)">
        <span>
          {{ tag.name }}
        </span>
      </lib-button-dropdown-menu-item>
    </lib-button-dropdown>
  </ng-container>
</ng-container>

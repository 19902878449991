import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '../../translate/translate.module';
import {
  ButtonDropdownModule,
  ButtonModule,
  FormElementsModule,
  ModalDialogLayoutModule,
  PagingControlModule,
} from '@lib/ui';
import { CNES_LIST_SERVICE_TOKEN, ModalCnesComponent } from './modal-cnes.component';
import { BarRatingModule } from 'ngx-bar-rating';
import { ListItemsService, ListItemsTableModule } from '@modules';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NotificationsModule } from '@lib/notifications';

@NgModule({
  declarations: [ModalCnesComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ModalDialogLayoutModule,
    FormElementsModule,
    ButtonModule,
    BarRatingModule,
    ListItemsTableModule,
    ButtonDropdownModule,
    NotificationsModule,
    PagingControlModule,
    MatProgressSpinnerModule,
  ],
  exports: [ModalCnesComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: CNES_LIST_SERVICE_TOKEN,
      useClass: ListItemsService,
    },
  ],
})
export class ModalCnesModule {}

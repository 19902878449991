import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IPatientFiltersParams } from '@project/data-providers';
import { IProfileTag } from '@project/view-models';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EPatientsModuleAction, PatientInviteManagerService } from '../patient-invite-manager.service';

@Component({
  selector: 'app-patient-invite-control',
  templateUrl: './patient-invite-control.component.html',
  styleUrls: ['./patient-invite-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientInviteControlComponent {
  public readonly actions = EPatientsModuleAction;
  public readonly filters$: Observable<Partial<IPatientFiltersParams>> = this.patientInviteManagerService.filtersState$;

  constructor(private patientInviteManagerService: PatientInviteManagerService) {
    this.patientInviteManagerService.dispatchAction(EPatientsModuleAction.LoadProfileTags);
  }

  public readonly profileTags$ = this.patientInviteManagerService.profileTags$;
  public readonly selectedTag$ = combineLatest([
    this.profileTags$,
    this.patientInviteManagerService.filtersState$,
  ]).pipe(
    map(([tags, filters]) => {
      const { tag } = filters;
      if (!tag) {
        return null;
      }
      return tags.find((t) => t.id === tag);
    }),
  );

  public setTagToPatient(tag: IProfileTag) {
    const filters: Partial<IPatientFiltersParams> = { tag: tag?.id };
    this.patientInviteManagerService.dispatchAction(EPatientsModuleAction.PatchFilters, filters);
  }
}

import { Injectable } from '@angular/core';
import { ApiService, IPagingResponse, PagingApiService } from '@core/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { map } from 'rxjs/operators';
import {
  EProfileTypeDTO,
  ICnes,
  IProfileTag,
  IProfileTagCreateData,
  IProfileTagDTO,
  ProfileTagViewModelFactory,
} from '@project/view-models';
import { TGuid } from '@core/helpers';

@Injectable({
  providedIn: 'root',
})
export class ProfilesTagsApiProviderService {
  constructor(private apiService: ApiService, private pagingApiService: PagingApiService) {}

  getAll(profileType: EProfileTypeDTO): Observable<IProfileTag[]> {
    return this.apiService
      .get<IProfileTagDTO[]>(`${environment.environmentVariables.apiCoreUrl}/profiles/tags?profileType=${profileType}`)
      .pipe(map((tagsDto) => tagsDto.map((dto) => ProfileTagViewModelFactory.createGetViewModel(dto))));
  }

  remove(id: TGuid): Observable<void> {
    return this.apiService.delete(`${environment.environmentVariables.apiCoreUrl}/profiles/tags/${id}`);
  }

  create(data: IProfileTagCreateData): Observable<IProfileTag> {
    const dto = ProfileTagViewModelFactory.createPostDTO(data);
    return this.apiService
      .post<IProfileTagDTO>(`${environment.environmentVariables.apiCoreUrl}/profiles/tags`, dto)
      .pipe(map((resultDto) => ProfileTagViewModelFactory.createGetViewModel(resultDto)));
  }

  getTagsWithoutProfile(params?: { search?: string }): Observable<IProfileTag[]> {
    const queryParams = params?.search ? `?search=${params.search}` : '';
    return this.apiService
      .get<IProfileTagDTO[]>(`${environment.environmentVariables.apiCoreUrl}/profiles/tags${queryParams}`)
      .pipe(map((tagsDto) => tagsDto.map((dto) => ProfileTagViewModelFactory.createGetViewModel(dto))));
  }

  getGroupById(id: TGuid): Observable<IProfileTag> {
    return this.getTagsWithoutProfile().pipe(map((tags) => tags.find((tag) => tag.id === id)));
  }

  update(id: TGuid, data: IProfileTagCreateData): Observable<void> {
    const dto = ProfileTagViewModelFactory.createPostDTO(data);
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/profiles/tags/${id}`, dto);
  }

  getCnes(
    itemsPerPage: number,
    page: number,
    uf: string,
    city?: string,
    search?: string,
  ): Observable<IPagingResponse<ICnes>> {
    const queryParams: Map<string, any> = new Map();
    queryParams.set('siglaestado', uf);
    queryParams.set('municipio', city);
    queryParams.set('filter', search);

    const queryString = this.createQueryString(queryParams);

    return this.pagingApiService.request<ICnes, ICnes>({
      url: `${environment.environmentVariables.apiCoreUrl}/profiles/tags/cnes/paged/${queryString}`,
      additionalQueryParams: queryParams,
      itemsPerPage,
      itemFactory: (item) => item,
      pageNumber: page,
    });
  }

  getCityCnes(uf: string): Observable<ICnes[]> {
    const params = new Map();
    params.set('siglaestado', uf);

    const queryString = this.createQueryString(params);

    return this.apiService.get(
      `${environment.environmentVariables.apiCoreUrl}/profiles/tags/cnes/municipio/${queryString}`,
    );
  }

  private createQueryString(map: Map<string, any>): string {
    const params = Array.from(map.entries())
      .filter(([key, value]) => value !== undefined && value !== null)
      .map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value))
      .join('&');

    return params ? `?${params}` : '';
  }
}

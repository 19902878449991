import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserInviteComponent } from './user-invite/user-invite.component';
import { LayoutElementsModule } from '../layout-elements/public-api';
import { ButtonDropdownModule, ButtonModule, CardModule, FormElementsModule, ModalDialogLayoutModule } from '@lib/ui';
import { IconsModule } from '@lib/icons';
import { AdminInviteDetailsFormComponent } from './admins/admin-invite-details-form/admin-invite-details-form.component';
import { DoctorInviteDetailsFormComponent } from './doctors/doctor-invite-details-form/doctor-invite-details-form.component';
import { PatientInviteDetailsFormComponent } from './patients/patient-invite-details-form/patient-invite-details-form.component';
import { HealthCenterAdminInviteDetailsFormComponent } from './health-center-admins/health-center-admin-invite-details-form/health-center-admin-invite-details-form.component';
import { ReceptionistInviteDetailsFormComponent } from './receptionists/receptionist-invite-details-form/receptionist-invite-details-form.component';
import { TranslateModule } from '@project/translate';
import { DoctorInviteImportModalComponent } from './doctors/doctor-invite-import-modal/doctor-invite-import-modal.component';
import { FormErrorsViewerModule } from '../form-errors-viewer/form-errors-viewer.module';
import { ReceptionistInviteImportModalComponent } from './receptionists/receptionist-invite-import-modal/receptionist-invite-import-modal.component';
import { PatientInviteControlComponent } from './patients/patient-invite-control/patient-invite-control.component';
import { PatientInviteManagerService } from './patients/patient-invite-manager.service';
import { ProfileTagsModule } from '../profile-tags/profile-tags.module';
import { PatientInviteImportModalComponent } from './patients/patient-invite-import-modal/patient-invite-import-modal.component';
import { ListItemsTableModule } from '../list-items-table/list-items-table.module';
import { MatButtonModule } from '@angular/material/button';
import { DoctorInviteControlComponent } from './doctors/doctor-invite-control/doctor-invite-control.component';
import { DoctorInviteManagerService } from './doctors/doctor-invite-manager.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { GroupsInviteDetailsFormComponent } from './groups/groups-invite-details-form/groups-invite-details-form.component';

@NgModule({
  declarations: [
    UserInviteComponent,
    AdminInviteDetailsFormComponent,
    DoctorInviteDetailsFormComponent,
    PatientInviteDetailsFormComponent,
    HealthCenterAdminInviteDetailsFormComponent,
    ReceptionistInviteDetailsFormComponent,
    DoctorInviteImportModalComponent,
    PatientInviteImportModalComponent,
    ReceptionistInviteImportModalComponent,
    DoctorInviteControlComponent,
    PatientInviteControlComponent,
    GroupsInviteDetailsFormComponent,
  ],
  exports: [UserInviteComponent, GroupsInviteDetailsFormComponent],
  imports: [
    CommonModule,
    LayoutElementsModule,
    IconsModule,
    FormElementsModule,
    ButtonModule,
    MatButtonModule,
    CardModule,
    TranslateModule,
    ModalDialogLayoutModule,
    FormErrorsViewerModule,
    ButtonDropdownModule,
    ProfileTagsModule,
    ListItemsTableModule,
    MatSlideToggleModule,
  ],
  providers: [PatientInviteManagerService, DoctorInviteManagerService],
})
export class UserInviteModule {}

import {
  CheckFirstRequest,
  CheckFirstResponse,
  IDevicePayload,
  ISignInRefreshTokenRequestDTO,
  ISignInRefreshTokenResponseDTO,
  ISingleDeviceAuthResult,
  ISingleDeviceAuthResultDTO,
  ProcessFirstAccessRequest,
} from '@project/view-models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AuthViewModelFactory,
  EProfileTypeDTO,
  IAuthResult,
  IAuthResultDTO,
  IConfirmUserRequestPayloadDTO,
  ISignUpByEmailRequestPayloadDTO,
  TEmailLoginRequestPayload,
} from '@project/view-models';
import { map, tap } from 'rxjs/operators';
import { environment } from '@env';
import { ApiService } from '@core/http';
import { TGuid } from '@core/helpers';
import { HcService } from '@project/services';
import { LocalStorageService } from '../../services/local-storage.service';

type TPossibleUserTypesToSignUp = EProfileTypeDTO.Patient;

@Injectable({
  providedIn: 'root',
})
export class AuthorizationApiProvider {
  constructor(
    private apiService: ApiService,
    private hcService: HcService,
    private localStorageService: LocalStorageService,
  ) {}

  emailLogin(email: string, password: string, device: IDevicePayload): Observable<IAuthResult> {
    const body: TEmailLoginRequestPayload = {
      credentials: {
        password,
        username: email,
      },
      device,
    };

    return this.apiService
      .post(`${environment.environmentVariables.apiAuthUrl}/signin/email`, body)
      .pipe(map((response: IAuthResultDTO) => AuthViewModelFactory.createFromDTO(response)))
      .pipe(
        tap((value) => {
          this.hcService.setHealthCenterId(value.healthCenterId);
        }),
      );
  }

  impersonateLogin(healthCenterId: string): Observable<IAuthResult> {
    return this.apiService
      .post(`${environment.environmentVariables.apiAuthUrl}/impersonate/doctor/signin/${healthCenterId}`)
      .pipe(map((response: IAuthResultDTO) => AuthViewModelFactory.createFromDTO(response)))
      .pipe(
        tap((value) => {
          this.localStorageService.store('logo-url', value.health_center_logo_url || 'assets/logos/logo.svg');
          this.hcService.setHealthCenterId(value.healthCenterId);
        }),
      );
  }

  singleDeviceLogin(email: string, password: string, device: IDevicePayload): Observable<ISingleDeviceAuthResult> {
    const body: TEmailLoginRequestPayload = {
      credentials: {
        password,
        username: email,
      },
      device,
    };

    return this.apiService
      .post(`${environment.environmentVariables.apiAuthUrl}/signin/single_device`, body)
      .pipe(map((response: ISingleDeviceAuthResultDTO) => AuthViewModelFactory.createFromSingleDeviceDTO(response)))
      .pipe(
        tap((value) => {
          if (!value.hasAnyOtherDeviceSignedIn) {
            this.hcService.setHealthCenterId(value.response.healthCenterId);
          }
        }),
      );
  }

  select(
    role: EProfileTypeDTO,
    healthCenterId: TGuid,
    token: string,
    dependentId: TGuid,
    qtdDependents: number,
  ): Observable<IAuthResult> {
    return this.apiService
      .post(`${environment.environmentVariables.apiAuthUrl}/signin/select`, {
        token,
        profile: role,
        health_center_id: healthCenterId,
        dependent_id: dependentId,
        qtd_dependents: qtdDependents,
      })
      .pipe(map((response: IAuthResultDTO) => AuthViewModelFactory.createFromDTO(response)))
      .pipe(
        tap((value) => {
          this.hcService.setHealthCenterId(value.healthCenterId);
        }),
      );
  }

  emailSignUp(
    email: string,
    password: string,
    userRole: TPossibleUserTypesToSignUp = EProfileTypeDTO.Patient,
  ): Observable<TGuid> {
    const body: ISignUpByEmailRequestPayloadDTO = {
      email,
      password,
    };

    return this.apiService.post(`${environment.environmentVariables.apiAuthUrl}/signup/email/${userRole}`, body);
  }

  get refreshTokePath(): string {
    return '/signin/refresh';
  }

  refreshToken(payload: ISignInRefreshTokenRequestDTO): Observable<ISignInRefreshTokenResponseDTO> {
    return this.apiService.post(`${environment.environmentVariables.apiAuthUrl}${this.refreshTokePath}`, payload);
  }

  facebookLogin(token: string): Observable<IAuthResult> {
    return this.apiService
      .post(`${environment.environmentVariables.apiAuthUrl}/signin/facebook`, token)
      .pipe(map((response: IAuthResultDTO) => AuthViewModelFactory.createFromDTO(response)));
  }

  googleLogin(code: string): Observable<IAuthResult> {
    return this.apiService
      .post(`${environment.environmentVariables.apiAuthUrl}/signin/google`, code)
      .pipe(map((response: IAuthResultDTO) => AuthViewModelFactory.createFromDTO(response)));
  }

  appleLogin(code: string): Observable<IAuthResult> {
    return this.apiService
      .post(`${environment.environmentVariables.apiAuthUrl}/signin/apple`, code)
      .pipe(map((response: IAuthResultDTO) => AuthViewModelFactory.createFromDTO(response)));
  }

  webTokenLogin(code: string): Observable<IAuthResult> {
    return this.apiService
      .post(`${environment.environmentVariables.apiAuthUrl}/signin/web`, code)
      .pipe(map((response: IAuthResultDTO) => AuthViewModelFactory.createFromDTO(response)));
  }

  embeddedLogin(code: string): Observable<IAuthResult> {
    return this.apiService
      .post(`${environment.environmentVariables.apiAuthUrl}/signin/embedded`, code)
      .pipe(map((response: IAuthResultDTO) => AuthViewModelFactory.createFromDTO(response)));
  }

  confirm(userId: TGuid, token: string): Observable<void> {
    const body: IConfirmUserRequestPayloadDTO = {
      token,
      user_id: userId,
    };

    return this.apiService.post(`${environment.environmentVariables.apiAuthUrl}/signup/email/confirm`, body);
  }

  sendPasswordRestoreEmail(email: string): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiAuthUrl}/signup/email/forgot`, { email });
  }

  checkFirstSignin(payload: CheckFirstRequest): Observable<CheckFirstResponse> {
    return this.apiService.post<CheckFirstResponse>(
      `${environment.environmentVariables.apiAuthUrl}/signup/firstaccess`,
      payload,
    );
  }

  processFirstSignin(payload: ProcessFirstAccessRequest): Observable<void> {
    return this.apiService.put<void>(`${environment.environmentVariables.apiAuthUrl}/signup/firstaccess`, payload);
  }

  resetPassword(userId: TGuid, token: string, password: string): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiAuthUrl}/signup/email/reset`, {
      id: userId,
      token,
      password,
      confirm_password: password,
    });
  }
}

import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ListItemsService } from '@modules';
import { GROUPS_LIST_SERVICE_TOKEN } from '../consts';
import { IProfileTag, IProfileTagCreateData } from '@project/view-models';
import { ProfilesTagsApiProviderService } from '@project/data-providers';
import { finalize, tap } from 'rxjs/operators';
import { EPageType } from '../../receptionists/services/receptionists-manager.service';

@Injectable({
  providedIn: 'root',
})
export class GroupsManagerService {
  public readonly groups$ = this.listService.currentPageItems$;
  public readonly filtersState$ = new BehaviorSubject<{ search: string | null }>({ search: null });
  public readonly hasActionInProgress$ = new BehaviorSubject<boolean>(false);
  private readonly _isFetching$ = new BehaviorSubject<boolean>(false);
  readonly _pagingState$ = new BehaviorSubject<{ currentPageNumber: number; totalPagesCount: number }>({
    currentPageNumber: 1,
    totalPagesCount: 1,
  });
  public readonly isFetching$ = this._isFetching$.asObservable();
  public readonly pagingState$ = this._pagingState$.asObservable();

  public itemsPerPage = 15;
  public currentPage = 1;

  private _pageType$ = new BehaviorSubject<string>(null);

  public lastInviteGroup$ = new BehaviorSubject<IProfileTag>(null);
  public lastDetailedGroup$ = new BehaviorSubject<IProfileTag>(null);

  constructor(
    @Inject(GROUPS_LIST_SERVICE_TOKEN) private listService: ListItemsService<IProfileTag>,
    private profilesTagsApiProviderService: ProfilesTagsApiProviderService,
  ) {}

  public setPageType(type: EPageType): void {
    this._pageType$.next(type);
    this.loadGroups().subscribe();
  }

  public loadGroups(): Observable<IProfileTag[]> {
    this._isFetching$.next(true);
    return this.profilesTagsApiProviderService.getTagsWithoutProfile().pipe(
      tap((groups) => this.applyPagination(groups)),
      finalize(() => this._isFetching$.next(false)),
    );
  }

  public loadGroupsPaginated(page: number): void {
    this._isFetching$.next(true);
    this.profilesTagsApiProviderService
      .getTagsWithoutProfile()
      .pipe(
        tap((groups) => this.paginateGroups(groups, page)),
        finalize(() => this._isFetching$.next(false)),
      )
      .subscribe();
  }

  public searchGroups(search: string): void {
    this.filtersState$.next({ search });
    this.filterAndPaginateGroups(search);
  }

  private applyPagination(groups: IProfileTag[]): void {
    this.paginateGroups(groups, 1);
  }

  private filterAndPaginateGroups(search: string, page: number = 1): void {
    this._isFetching$.next(true);
    this.profilesTagsApiProviderService
      .getTagsWithoutProfile()
      .pipe(
        tap((groups) => {
          const filteredGroups = groups.filter((group) => group.name.toLowerCase().includes(search.toLowerCase()));
          this.paginateGroups(filteredGroups, page);
        }),
        finalize(() => this._isFetching$.next(false)),
      )
      .subscribe();
  }

  private paginateGroups(groups: IProfileTag[], page: number): void {
    const startIndex = (page - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    const paginatedGroups = groups.slice(startIndex, endIndex);

    this.listService.setItems(paginatedGroups);
    this._pagingState$.next({
      currentPageNumber: page,
      totalPagesCount: Math.ceil(groups.length / this.itemsPerPage),
    });
  }

  public setDetailGroup(id: string): Observable<IProfileTag> {
    return this.profilesTagsApiProviderService.getGroupById(id).pipe(
      tap((group) => {
        if (group) {
          this.lastDetailedGroup$.next(group);
        }
      }),
    );
  }

  public update(id: string, data: IProfileTagCreateData): Observable<void> {
    return this.profilesTagsApiProviderService.update(id, data);
  }

  public setDetailGroupV2(profileTag: IProfileTag): void {
    this.lastDetailedGroup$.next(profileTag);
  }
}
